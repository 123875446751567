.Title-style-Head {
    font-size: 1.5rem;
    font-weight: bolder;
    letter-spacing: 1;
}

.Title-style1 {
    font-size: 1.25rem;
    font-weight: bolder;
}

.button-style-rec {
    border: none; 
    margin-top: 5rem; 
    font-size: 0.85rem; 
    font-style: italic; 
    font-family: 'Times New Roman'; 
    font-weight: 200; 
    /* padding-left: 5rem; 
    padding-right: 5rem; */
    /* min-width: 50% */
}

@media (max-width: 425px) {
    .Title-style-Head {
        font-size: 1.25rem;
    }

    .Title-style1 {
        font-size: 1rem;

    }
}

@media (width: 768px) {
    .Title-style-Head {
        font-size: 1.5rem;
    }

    .Title-style1 {
        font-size: 1.25rem;
    }
}