.title-style-head2{
    font-size: 1.5rem;
    font-weight: bolder;
    letter-spacing: 1;
}

.img-style{
    width: 13rem
}

.img-style2{
    width: 25rem
}

/* .Title-style {
    font-size: 2.25rem;
} */

.subTitle-style {
    font-size: 1.25rem;
}

 .divider1 {
    border-left: 1px solid #2c3e50; /* Correct usage of border-left */
    height: 100px; /* Add units for the height */
    margin: 0 20px; /* Adjust spacing around the line */
  }


  /* @media (min-width: 768px) {
  
  } */

@media (max-width: 425px) {
    .img-style2{
        width: 16rem,
    }

    .title-style-head2{
        font-size: 1.25rem;
    }
    
}

@media (min-width: 768px) {
    .title-style-head2{
        font-size: 1.5rem;
    }
}

@media (max-width: 768px) {

    .img-style{
        width: 7rem
    }

    .img-style2{
        width: 15rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .Title-style {
        font-size: 1rem;
    }

    .subTitle-style {
        font-size: 0.75rem;
    }
}


@media (max-width: 1024px) {
    .divider-parent{
        width: 100%;
    }

}