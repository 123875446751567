.box-style {
    min-width: 20%;
    /* min-height: 20rem; */
    margin-left: 1rem;
    margin-right: 2rem;
}

.Title-style {
    font-size: 3rem;
}

.view-messages {
    width: 70%;
    /* background-color: red;s   */
    align-self: center;
}

.view-message-container {
    width: 50%;
}

@media (max-width: 425px) {
    .box-style {
        min-width: 100%;
    }

    .Title-style {
        font-size: 1.75rem;
    }

    .view-message-container {
        width: 80%;
    }
}

@media (min-width: 425px) {
    .box-style {
        min-width: 100%;
    }
}

@media (min-width: 768px) {
    .Title-style {
        font-size: 2rem;
    }

}

@media (min-width: 1024px) {
    .box-style {
        min-width: 70%;
    }

}

@media (min-width: 1024px) {
    .box-style {
        min-width: 60%;
    }
}

@media (min-width: 1440px)   {
    .box-style {
        min-width: 40%;
    }
    
}

@media (min-width: 2560px) {
    .box-style {
        min-width: 30%;
    }
}