@font-face {
    font-family: 'CustomFont';
    src: url('./Fonts/Slight-RegularOriginal.ttf') format('truetype');
    /* src: url('./Fonts/Slight1.ttf') format('truetype'); */
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'CustomFont';
    /* background-color: red; */
    margin: 0;
    overflow-x: hidden;
}