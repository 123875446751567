.input-style {
    border: none;
}

.line-style {
    width: 25%;
}

.Title-style {
    font-size: 6rem;
}

@media only screen and (device-width: 430px) and (device-height: 932px) {
    /* Styles specifically for iPhone 14 Pro Max */
    .Title-style {
        font-size: 15px;
        width: 100%;
        align-self: center;
        text-align: center;
    }


}

@media (max-width: 425px) {

    .Title-style {
        font-size: 8rem;
    }

}

@media (min-width: 768px) {
    .Title-style {
        font-size: 7rem;
    }
}


@media (max-width: 768px) {
    .line-style {
        width: 50%;
    }
    
}