.image-style {
    width: 60%;
    height: 25rem; 
    margin-top: 7rem; 
    margin-bottom: 10rem; 
}

.title-style-head{
    font-size: 2rem; 
    font-weight: bolder; 
    letter-spacing: 1; 
}

.SubStyle {
    font-size: 1.5rem;
    /* { color: primaryTxtClr, fontWeight: 200, fontFamily: "Times New Roman", fontStyle:'italic', textAlign:"center", marginTop:'2rem', minWidth:'40%', maxWidth: '50%' } */
    font-weight: 200;
    font-family: "Times New Roman";
    font-style: italic;
    text-align: center;
    margin-top: 2rem;
    /* min-width: 40%; */
    max-width: 45%; 
}

.image-style-2 {
    /* width: 80%; */
    width: 75%;
    height: 28rem; 
    margin-top: 3rem; 
    margin-bottom: 5rem; 
}

@media only screen and (device-width: 430px) and (device-height: 932px) {
    /* Styles specifically for iPhone 14 Pro Max */

    .image-style {
        display: none;
    }


}  

@media (max-width: 425px) {
    .title-style-head{
        font-size: 1.25rem;
    }

    .image-style {
        display: none;
    }
}

@media (min-width: 768px) {
    .title-style-head{
        font-size: 1.5rem;
    }

    .image-style-2 {
        display: none;
        width: 60%;
        height: 25rem; 
        margin-top: 7rem; 
        margin-bottom: 10rem; 
    }

}

@media (max-width: 768px) {
    .SubStyle {
        font-size: 1rem;
        max-width: 90%; 
    }

    .image-style {
        height: 20rem; 
    }
  
}

@media (max-width: 1024px) {
    .image-style {
        width: 100%;
        /* height: 25rem;  */
        margin-top: 5rem; 
        margin-bottom: 8rem; 
    }
    /* .SubStyle {
        max-width: 70%; 
    } */
}

@media (min-width: 1024px) {
    .image-style {
        width: 85%;
        height: 25rem; 
        margin-top: 5rem; 
        margin-bottom: 8rem; 
    }
    .SubStyle {
        max-width: 70%; 
    }
   
}

@media (min-width: 1440px)   {
    .image-style {
        width: 70%;
        height: 27rem; 
        margin-top: 5rem; 
        margin-bottom: 8rem; 
    }
}

@media (min-width: 2560px) {
    .image-style {
        width: 60%;
        height: 30rem; 
        margin-top: 7rem; 
        margin-bottom: 10rem; 
    }

    .SubStyle {
        max-width: 39%; 
    }
}
  