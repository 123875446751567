.welcome-letter-style{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: -14rem;
}

.scroll-to-top-style {
  position: absolute;
  width: 10rem;
  height: 10rem;
  top:100rem;
  right:1rem
}


.home-container {
  width: 100%; 
  height: 65vh;  
  display: flex; 
  align-items: center; 
  flex-direction: column; 
  padding-top: 10rem; /*  Before was 7rem  */
}

.heading-style {
  font-size: 2rem; 
  font-weight: bolder; 
  letter-spacing: 1; 
}

.text-style {
  font-size: 1.25rem; 
}

.form-style {
  width: 50%;
}

.form-style1 {
  width: 40%;
}

.header-style {
  font-size: 1.5rem;
}

.sub-header-style {
  font-size: 1.5rem;
}

@media only screen and (device-width: 430px) and (device-height: 932px) {
  /* Styles specifically for iPhone 14 Pro Max */

  .heading-style {
    font-size: 1.25rem; 
  }

  .text-style {
    font-size: 0.75rem; 
  }
  
  .header-style {
    font-size: 1rem;
  }
  
  .sub-header-style {
    font-size: 1rem;
  }

  .welcome-letter-style{
    width: 75%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: 0rem;
  }

  .home-container {
    height: 50vh;  
    padding-top: 7rem;
  }

}

@media (max-width: 425px) {
  .welcome-letter-style{
    width: 75%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: 3rem;
  }

  .heading-style {
    font-size: 1.25rem; 
  }

  .text-style {
    font-size: 0.75rem; 
  }

  .form-style {
    width: 80%;
  }

  /* .form-style1 {
    width: 80%;
  } */

  .header-style {
    font-size: 1rem;
  }
  
  .sub-header-style {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .welcome-letter-style{
    width: 75%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: -8rem;
  }

  .heading-style {
    font-size: 1.5rem; 
  }

  .text-style {
    font-size: 0.85rem; 
  }
}

/* @media (max-width: 768px) {
  .header-style {
    font-size: 1.25rem;
  }
  
  .sub-header-style {
    font-size: 1.25rem;
  }
} */

@media (max-width: 1024px) {

}

@media (min-width: 1024px) {
  .welcome-letter-style{
    width: 75%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: -10rem;
  }

  .text-style {
    font-size: 1rem; 
  }
}

@media (max-width: 1024px) {
  .form-style1 {
    width: 80%;
  }
}

@media (min-width: 1440px)   {
  .welcome-letter-style{
    width: 60%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-top: -13rem;
  }

  .text-style {
    font-size: 1.25rem; 
  }
}

@media (min-width: 2560px) {
  .welcome-letter-style{
    width: 40%;
    padding-top: 7rem;
    padding-bottom: 7rem;
    margin-top: -16rem;
  }

  .text-style {
    font-size: 1.25rem; 
  }


  .home-container {
    width: 100%; 
    height: 65vh;  
    display: flex; 
    align-items: center; 
    flex-direction: column; 
    padding-top: 13rem; /*  Before was 7rem  */
  }
}
