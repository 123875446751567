/* PartyPage.css */
.party-page {
    padding: 20px;
    font-family: Arial, sans-serif;
    padding-top: 3rem;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .section-content {
    text-align: center;
  }
  
  .section-content h3 {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .section-content p {
    font-size: 1rem;
    margin-top: 5px;
  }
  
  /* Divider between sections */
  .divider {
    /* border: none;
    border-top: 1px solid #2c3e50;
    margin: 20px 0;
    min-height: 500; */
    border-left: 1px solid #2c3e50; /* Correct usage of border-left */
    height: 100px; /* Add units for the height */
    margin: 0 20px; /* Adjust spacing around the line */
  }


  /* Responsive Design */
  @media (max-width: 768px) {
    .section-title {
      font-size: 1.15rem;
    }
  
    .section-content h3 {
      font-size: 1rem;
    }
  
    .section-content p {
      font-size: 0.9rem;
    }
  }
  