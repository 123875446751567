.heading-title{
    font-family: 'CustomFont'; 
    font-size: 2.5rem;
    text-align: center;
    margin-top: 0.56rem;
}

.navItem-title {
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    padding: 0 15px;
    &:hover {
        text-decoration: underline;
    }
}

@media only screen and (device-width: 430px) and (device-height: 932px) {
    /* Styles specifically for iPhone 14 Pro Max */
    .heading-title{
        font-size: 1.25rem;
        margin-top: 0.75rem;
        text-align: center;
    }
  }

@media (max-width: 425px) {
    .heading-title{
        font-size: 1.25rem;
        margin-top: 0.75rem;
        text-align: center;
    }
}

@media (width: 768px) {
    .heading-title{
        font-size: 1.25rem;
        margin-top: 0.56rem;
    }

    .navItem-title {
        font-size: 0.75rem;
        padding: 0 5px;
    }
}

@media (width: 1024px) {
    .heading-title{
        font-size: 1.75rem;
        margin-top: 0.56rem;
    }

    .navItem-title {
        font-size: 0.75rem;
        padding: 0 5px;
    }
}