.question-box {
    width: 50%;
    margin-top: 4%;
}

.title-style-head3{
    font-size: 1.5rem;
    font-weight: bolder;
}

@media (max-width: 425px) {
    .question-box {
        width: 90%;
        margin-top: 4%;
    }

    .title-style-head3{
        font-size: 1.25rem;
    }
}

@media (min-width: 768px) {
    .question-box {
        width: 80%;
        margin-top: 4%;
    }
}

@media (min-width: 1024px) {
    .question-box {
        width: 80%;
        margin-top: 4%;
    }
}